<template>
  <section class="pt-4 px-0 px-xl-4">
    <b-container fluid>
      <b-row class="mb-3">
        <b-col>
          <h3>Configurazione stampanti <font-awesome-icon icon="print" /></h3>
        </b-col>
      </b-row>
      <b-card class="mb-3" v-for="(element, key) in data" :key="key">
        <b-form-row>
          <b-col class="mb-3" cols="6">
            <b-form-input
              placeholder="location"
              v-model="element.location"
            ></b-form-input>
          </b-col>
          <b-col cols="6" class="text-right">
            <b-button variant="danger" @click="removePrintServer(key)"
              >Elimina <font-awesome-icon icon="trash"
            /></b-button>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col class="mb-3" cols="6">
            <b-form-input
              placeholder="print server"
              v-model="element.printServer"
            ></b-form-input>
          </b-col>
          <b-col class="mb-3" cols="6">
            <b-form-input
              placeholder="cups server"
              v-model="element.cupsServer"
            ></b-form-input>
          </b-col>
        </b-form-row>
        <b-form-row v-if="false">
          <b-col cols="2">
            <label>work station n.</label>
          </b-col>
          <b-col cols="3">
            <label>label</label>
          </b-col>
          <b-col cols="5">
            <label>printer address/name</label>
          </b-col>
          <b-col class="text-right" cols="1">
            <label>raw</label>
          </b-col>
          <b-col cols="1"> </b-col>
        </b-form-row>
        <b-form-row v-for="(item, index) in element.config" :key="index">
          <b-col class="mb-3" cols="2">
            <b-form-input
              type="number"
              min="1"
              placeholder="work station number"
              v-model="item.workStationNumber"
            ></b-form-input>
          </b-col>
          <b-col cols="3">
            <b-form-input
              placeholder="label"
              v-model="item.label"
            ></b-form-input>
          </b-col>
          <b-col cols="5">
            <b-form-input
              placeholder="printer address"
              v-model="item.printerAddress"
            ></b-form-input>
          </b-col>
          <b-col class="text-right" cols="1">
            <b-checkbox v-model="item.raw">raw</b-checkbox>
          </b-col>
          <b-col class="text-right" cols="1">
            <b-button variant="danger" @click="removePrinter(key, index)"
              ><font-awesome-icon icon="trash"
            /></b-button>
          </b-col>
        </b-form-row>
        <b-form-row class="mb-3">
          <b-col>
            <b-button @click="addPrinter(key)">
              <font-awesome-icon icon="plus"
            /></b-button>
          </b-col>
        </b-form-row>
      </b-card>
      <b-row class="mb-3">
        <b-col>
          <b-button variant="primary" @click="addPrintServer">
            <font-awesome-icon icon="plus"
          /></b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          ><b-button @click="set" variant="primary" block size="lg"
            >Salva</b-button
          ></b-col
        >
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "PrinterConfiguration",
  data() {
    return {
      saved: null,
      data: []
    };
  },
  beforeMount() {
    this.get();
  },
  methods: {
    set: function() {
      this.$http
        .post(`${process.env.VUE_APP_API_ENDPOINT}config/printer`, {
          data: this.data
        })
        .then(res => {
          this.saved = !!res.data;
        })
        .catch(err => {
          this.error = err;
        });
    },
    get: function() {
      this.$http
        .get(`${process.env.VUE_APP_API_ENDPOINT}config/printer/get`)
        .then(res => {
          this.data = res.data;
        })
        .catch(err => {
          this.error = err;
        });
    },
    addPrinter: function(key) {
      this.data[key].config.push({
        workStationNumber: null,
        label: null,
        printerAddress: null,
        raw: false
      });
    },
    removePrinter: function(key, index) {
      this.data[key].config.splice(index, 1);
    },
    addPrintServer: function() {
      this.data.push({
        location: null,
        printServer: null,
        cupsServer: null,
        config: []
      });
    },
    removePrintServer: function(key) {
      this.data.splice(key, 1);
    }
  }
};
</script>
